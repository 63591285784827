import { UserModel } from '@/models/UserModel'

export default [
  {
    path: '/calls/:callsSource',
    name: 'calls',
    component: () => import('@/views/Calls/Calls'),
    meta: {
      actions: [UserModel.ACTION_VIEW_ALL_CALLS, UserModel.ACTION_TRAINING_VIEW_ALL_CALLS],
    }
  },
  {
    path: '/calls/:callsSource/score/:leadScoreId',
    name: 'calls.score',
    component: () => import('@/views/ScoringPage/ScoringPage'),
    meta: {
      actions: [UserModel.ACTION_SCORE_CALL, UserModel.ACTION_TRAINING_SCORE_CALL],
    }
  },
  {
    path: '/refresh-leads',
    name: 'refresh.leads',
    component: () => import('@/views/RefreshLeads/RefreshLeads'),
    meta: {
      actions: [UserModel.ACTION_WILDCARD],
    }
  }
]
