import Vue from 'vue'
import { UserModel } from '@/models/UserModel'
import store from '@/store'
import router from '@/router'
import get from 'lodash/get'

class Acl {
  constructor ({ rolesActions } = {}) {
    this.rolesActions = rolesActions ? rolesActions : Acl.getRolesActions()
    this.guardEnabled = false
  }

  can (action, user) {
    if (!user) {
      user = this.getAuthUser()
    }

    const allowedActions = get(
      this.rolesActions,
      `${user.role}`,
      []
    )

    if (allowedActions.indexOf(UserModel.ACTION_WILDCARD) > -1) {
      return true
    }

    return allowedActions.indexOf(action) > -1
  }

  getAuthUser () {
    return store.state.auth.user
  }

  initRouterGuard (to) {
    // this method is used only once,
    // the first time when the app is initialized and the authentication is done
    const isAllowed = this.canRoute(to)

    if (!this.guardEnabled) {
      // from now on, router guard will be used
      router.beforeEach(
        (to, from, next) => this.routerGuard(to, from, next)
      )
      this.guardEnabled = true
    }

    if (!isAllowed) {
      return router.push(this.getFallbackRoute(to)).catch()
    }
  }

  canRoute (to) {
    if (!to.matched) {
      // in case a plain route is provided, we will resolve it.
      to = router.resolve(to).route
    }

    const requiredActions = get(to, 'meta.actions', [])
    if (!requiredActions.length) {
      //we don't any permission for this route
      return true
    }

    let isAllowed = false

    requiredActions.forEach((action) => {
      const can = this.can(action)
      if (can) {
        // if at least one of the actions is allowed, user is allowed.
        isAllowed = true
        return false
      }
    })

    if (isAllowed) {
      switch (to.name) {
        case 'calls':
        case 'calls.score':
          const callSource = to.params.callsSource
          return this.validateCallsSource(callSource)
      }
    }

    return isAllowed
  }

  getFallbackRoute (to) {
    let fallback = {
      name: 'forbidden'
    }

    const user = this.getAuthUser()

    if (!user) {
      return fallback
    }

    switch (user.role) {
      case UserModel.ROLE_ADMIN:
      case UserModel.ROLE_AGENT:
        return {
          name: 'calls',
          params: {
            callsSource: 'scoringQueue'
          }
        }
      case UserModel.ROLE_TRAINING:
        return {
          name: 'calls',
          params: {
            callsSource: 'trainingQueue'
          }
        }
    }

    return fallback
  }

  static roleCallSources () {
    const roleCallSources = {
      [UserModel.ROLE_AGENT]: [
        'scoringQueue',
      ],
      [UserModel.ROLE_TRAINING]: [
        'trainingQueue'
      ]
    }

    return roleCallSources
  }

  validateCallsSource (callSource) {
    if (this.can(UserModel.ACTION_WILDCARD)) {
      return true
    }

    const user = this.getAuthUser()
    const role = user.role
    const allowedSources = get(Acl.roleCallSources(), `${role}`, [])
    return allowedSources.indexOf(callSource) > -1
  }

  routerGuard (to, from, next) {
    const can = this.canRoute(to)
    if (can) {
      return next()
    }

    return next(this.getFallbackRoute(to))
  }

  static getRolesActions () {
    return {
      [UserModel.ROLE_ADMIN]: [
        UserModel.ACTION_WILDCARD
      ],
      [UserModel.ROLE_AGENT]: [
        UserModel.ACTION_VIEW_ALL_CALLS,
        UserModel.ACTION_SCORE_CALL,
        UserModel.ACTION_VIEW_OUTSTANDING_CALLS,
      ],
      [UserModel.ROLE_TRAINING]: [
        UserModel.ACTION_TRAINING_VIEW_ALL_CALLS,
        UserModel.ACTION_TRAINING_SCORE_CALL
      ],
    }
  }
}

const acl = new Acl()

export default acl

Vue.prototype.$acl = acl

