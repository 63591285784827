// These values are pulled from .env on development builds and .env.prod for production build.
export const LOGIN_AUTHORITY = process.env.VUE_APP_LOGIN_AUTHORITY
export const LOGIN_HOME = process.env.VUE_APP_LOGIN_HOME
export const LOGIN_CLIENT = process.env.VUE_APP_LOGIN_CLIENT

export const OMNI_ENV = process.env.VUE_APP_OMNI_ENV
export const LEAD_SCORE_API_BASE_URL = process.env.VUE_APP_LEAD_SCORE_API_BASE_URL
export const BASE = process.env.VUE_APP_BASE

export const oidcSettings = {
  authority: LOGIN_AUTHORITY,
  clientId: LOGIN_CLIENT,
  redirectUri: `${window.location.origin}/auth-callback`,
  postLogoutRedirectUri: `${LOGIN_HOME}`,
  responseType: 'code',
  scope: 'openid leadscore',
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: true,
  automaticSilentSignin: true,
  accessTokenExpiringNotificationTime: 60, // number of seconds BEFORE expiration to renew -- default is 60
  silentRedirectUri: `${window.location.origin}/omnibar-silent-refresh.html`,
  silent_redirect_uri: `${window.location.origin}/omnibar-silent-refresh.html`
}
