import Vue from 'vue'
import moment from 'moment'
import isNil from 'lodash/isNil';

Vue.filter('date', (value, format = 'MM/DD/YYYY') => {
  if (!value) {
    return value
  }
  return moment(value).format(format)
})

Vue.filter('duration', (seconds, format = 'mm:ss') => {
  if (isNil(seconds)) {
    return seconds
  }
  return moment().startOf('day').seconds(seconds).format(format)
})
