import Vue from 'vue'

Vue.prototype.$raulSnackbar = (message, title, variant = 'danger', cta, timeout = 3000) => {
  window.RAUL.commands.toast({
    timeout: timeout,
    heading: title,
    content: message,
    ctaMessage: cta,
    tagName: 'raul-snackbar',
    dismissable: true,
    variant
  })
}
